import React from 'react';
import PropTypes from 'prop-types';
import TicketCard from '../ticket-card';
import Slider from 'react-slick';
import tickets_data from './ticket_data.json';

export default class Tickets extends React.Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    isDesktopCarousel: PropTypes.bool
  };

  static defaultProps = {
    isMobile: false,
    isDesktopCarousel: false
  };

  render() {
    const { id, isMobile, isDesktopCarousel } = this.props;
    let renderCallback;

    if (isMobile) renderCallback = this.renderMobile.bind(this, null);
    else if (isDesktopCarousel)
      renderCallback = renderCallback = this.renderDesktopCarousel.bind(
        this,
        null
      );
    else renderCallback = this.renderDesktop.bind(this, null);

    return (
      <section id={id} className="Tickets__container">
        <h1 className="Tickets__container__title">
          <span className="Tickets__container__title__gradTxt">
            Buy your tickets
          </span>
        </h1>
        <span className="Tickets__container__description">
          Grab yours before they run out
        </span>
        {renderCallback()}
      </section>
    );
  }

  getTicketCards = () => {
    let ticketCardNodes = [];
    tickets_data.forEach(ticket => {
      ticketCardNodes.push(
        <TicketCard
          className="Tickets__cards__card"
          variant={ticket.type}
          title={ticket.title}
          price={ticket.price}
          description={ticket.description}
          ticketLink={`https://ti.to/region8/wie-ils/with/${ticket.tito_id}`}
        />
      );
    });

    return ticketCardNodes;
  };

  renderMobile = () => (
    <div className="Tickets__carouselContainer">
      <Slider
        slidesToShow={1}
        slidesToScroll={1}
        dots
        arrows={false}
        dotsClass="Tickets__carouselDots"
      >
        {this.getTicketCards().map(ticket_card => (
          <div className="Tickets__carousel__container">{ticket_card}</div>
        ))}
      </Slider>
    </div>
  );

  renderDesktop = () => (
    <div className="Tickets__container__cards">{this.getTicketCards()}</div>
  );

  renderDesktopCarousel = () => {
    const tickets = this.getTicketCards();
    let divNodeArray = [];
    for (let i = 0; i < tickets.length / 2; i++) {
      divNodeArray.push(
        <div className="Tickets__carousel__container">
          {tickets[i * 2]}
          {tickets[i * 2 + 1] ? tickets[i * 2 + 1] : ''}
        </div>
      );
    }
    return (
      <div className="Tickets__carouselContainer">
        <Slider
          slidesToShow={1}
          slidesToScroll={1}
          dots
          arrows={false}
          dotsClass="Tickets__carouselDots"
        >
          {divNodeArray}
        </Slider>
      </div>
    );
  };
}
