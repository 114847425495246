import React from 'react';
import FeatureCard from '../feature-card';
import icon_event_speakers from '../../assets/icon-event-speakers.svg';
import icon_event_networking from '../../assets/icon-event-networking.svg';
import icon_event_people from '../../assets/icon-event-people.svg';
import icon_event_fun from '../../assets/icon-event-fun.svg';

const EventFeatures = () => (
  <div className="EventFeatures__container">
    <h1 className="EventFeatures__container__title">
      <span className="EventFeatures__container__title__gradTxt">
        What you can expect from this event
      </span>
    </h1>
    <div className="EventFeatures__featureCardContainer">
      <FeatureCard
        sources={icon_event_speakers}
        title="Great Speakers"
        text="Amazing women in the technology field, with great stories to share."
      />
      <FeatureCard
        sources={icon_event_networking}
        title="Networking"
        text="Share insights and strengthen connections."
      />
      <FeatureCard
        sources={icon_event_people}
        title="New People"
        text="Meet new people, open new doors for your future."
      />
      <FeatureCard
        sources={icon_event_fun}
        title="Lots of Fun"
        text="We promise it will be worth it."
      />
    </div>
  </div>
);

export default EventFeatures;
