import React, { Component, Fragment } from 'react';

export default class Tracks extends Component {
  constructor(props) {
    super(props);

    const { isMobile } = this.props;

    this.state = {
      currentTrack: isMobile ? undefined : 0
    };
  }

  render() {
    const { isMobile } = this.props;

    return (
      <Fragment>
        {isMobile ? this.renderMobile() : this.renderDesktop()}
      </Fragment>
    );
  }

  renderDesktop() {
    const { currentTrack } = this.state;
    const { tracks } = this.props;

    return (
      <div className="Tracks__container">
        <div>
          {tracks.map((session, index) => (
            <div
              className="Tracks__session"
              key={index}
              onClick={() => this.handleClick(index)}
            >
              <h1
                className={`Tracks__sessionTitle${
                  index === currentTrack ? 'Selected' : ''
                }`}
              >{`Session ${index + 1}`}</h1>
              <div
                className={`Tracks__sessionInfo${
                  index === currentTrack ? 'Selected' : ''
                }`}
              >
                <p className="Tracks__sessionDate">{session.date}</p>
                <p>{session.time}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="Tracks__sessionListing">
          {tracks[currentTrack].talks.map((talk, index) => (
            <div className="Tracks__talk" key={index}>
              <h1 className="Tracks__talkName">{talk.name}</h1>
              <p className="Tracks__talkTitle">{talk.title}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderMobile() {
    const { tracks } = this.props;
    const { currentTrack } = this.state;

    return (
      <div>
        {tracks.map((session, index) => (
          <Fragment>
            <div
              className="Tracks__session"
              onClick={() => this.handleClick(index)}
            >
              <h1 className="Tracks__sessionTitleSelected">{`Session ${index +
                1}`}</h1>
              <div className="Tracks__sessionInfoSelected">
                <p>{`${session.date} ${session.time}`}</p>
              </div>
            </div>
            {index === currentTrack &&
              session.talks.map((talk, index) => (
                <div className="Tracks__talk" key={index}>
                  <h1 className="Tracks__talkName">{talk.name}</h1>
                  <p className="Tracks__talkTitle">{talk.title}</p>
                </div>
              ))}
          </Fragment>
        ))}
      </div>
    );
  }

  toggleCard = () => {
    this.setState(({ opened }) => ({ opened: !opened }));
  };

  handleClick = newTrack => {
    const { isMobile } = this.props;
    const { currentTrack } = this.state;

    if (currentTrack === newTrack) {
      if (isMobile) {
        this.setState({ currentTrack: undefined });
      }
      return;
    }

    this.setState({ currentTrack: newTrack });
  };
}
