import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default class VenuesCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      active,
      text,
      light_icon,
      active_icon,
      onClick,
      className,
      content
    } = this.props;
    const filename = !active ? light_icon : active_icon;
    return (
      <div
        className={
          className +
          ' Venues__ItemCard' +
          (active ? ' Venues__ItemCard--active' : '')
        }
        onClick={onClick}
      >
        <div className="Venues__ItemCard__Header">
          <img
            className="Venues__ItemCard__Icon"
            src={require(`./../../assets/${filename}`)}
            alt="icon"
          />
          <span className="Venues__ItemCard__Text">{text}</span>
        </div>
        {content && active ? (
          <div className="Venues__ItemCard__Body">{content}</div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

VenuesCard.defaultProps = {
  active: false
};

VenuesCard.propTypes = {
  active: PropTypes.bool,
  text: PropTypes.string.isRequired,
  light_icon: PropTypes.string.isRequired,
  active_icon: PropTypes.string.isRequired
};
