import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';

export default class Footer extends React.Component {
  getQuickLinks() {
    return (
      <div className="footer--main--quicklinks">
        <h1>Quick Links</h1>
        <ul>
          <li>
            <Link to={{ pathname: '/', hash: 'schedule' }}>Schedule</Link>
          </li>
          <li>
            <Link to="/speakers">Speakers</Link>
          </li>
          <li>
            <Link to="/sponsors">Sponsors</Link>
          </li>
          <li>
            <Link to="/venues">Venues</Link>
          </li>
          <li>
            <Link to={{ pathname: '/', hash: 'tickets' }}>Tickets</Link>
          </li>
        </ul>
      </div>
    );
  }

  getContactsAddress() {
    const email = 'wieilsportugal@gmail.com';
    return (
      <div className="footer--main--info">
        <div className="footer--main--info--address">
          <h1>Address</h1>
          <span>J201, FEUP</span>
          <span>Rua Dr Roberto Frias, 4200-465 Porto, Portugal</span>
        </div>
        <div className="footer--main--info--contact">
          <h1>Contact</h1>
          <address>
            <a href={`mailto:${email}`}>{email}</a>
          </address>
        </div>
      </div>
    );
  }

  getSocialMedia() {
    const facebook = 'https://www.facebook.com/wie.ils.pt/';
    const instagram = 'https://www.instagram.com/wie.ils.pt/';
    const twitter = 'https://twitter.com/wie_ils_pt';
    return (
      <div className="footer--main--social">
        <h1>Find us</h1>
        <div className="footer--main--social--icons">
          <a href={facebook}>
            <i className="fab fa-facebook"></i>
          </a>
          <a href={instagram}>
            <i className="fab fa-instagram"></i>
          </a>
          <a href={twitter}>
            <i className="fab fa-twitter"></i>
          </a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <footer className="footer">
        <div className="container">
          <section className="footer--main">
            <a
              className="footer--main--logo"
              href="https://wie.ieee.org/leadership-summits2019/"
              target="_blank"
            >
              <img
                src={require('../../assets/wie_ils_logo_black.svg')}
                alt="WIE ILS logo"
              />
            </a>
            {this.getQuickLinks()}
            {this.getContactsAddress()}
            {this.getSocialMedia()}
          </section>
        </div>
        <section className="footer--trademark">
          <div className="footer--trademark--items container">
            <span className="trademark--copyright">
              Copyright © all rights reserved, 2019
            </span>
            <Link className="trademark--privacy_policy" to="/privacy-policy">
              Privacy Policy
            </Link>
            <span className="trademark--brand">IEEE WIE</span>
          </div>
        </section>
      </footer>
    );
  }
}
