import React from 'react';
import PropTypes from 'prop-types';

export default class PastEventCard extends React.Component {
  constructor(props) {
    super(props);
    // if mobile viewport, all cards should be collapsed
    this.state = { isCollapsed: this.props.width < 768 };
  }

  handleCardClick() {
    if (this.props.width <= 768)
      // toogle the collapse state
      this.setState({ isCollapsed: !this.state.isCollapsed });
  }

  render() {
    if (this.props.width > 768) this.state.isCollapsed = false;

    let { thumbnail, title, date, description } = this.props;
    return (
      <div
        className={
          'past-event-card' +
          (this.props.className ? ' ' + this.props.className : '')
        }
        onClick={() => this.handleCardClick()}
      >
        <div className="past-event-card__thumbnail">
          <img className="thumbnail" src={thumbnail} />
        </div>
        <div className="past-event-card__details">
          <h1 className="details__title">{title}</h1>
          <h2 className="details__date">{date}</h2>
          <div
            className={
              'details__description__container' +
              (this.state.isCollapsed ? '--collapsed' : '')
            }
          >
            <p className="details__description">{description}</p>
          </div>
        </div>
      </div>
    );
  }
}

PastEventCard.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};
