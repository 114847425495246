import React, { Component } from 'react';

import PersonCard from '../../components/person-card';
import Button from '../../components/button';

import testImage from '../../assets/hero.jpeg';

export default class TeamPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullText: false
    };
  }

  render() {
    const { isMobile } = this.props;
    const { fullText } = this.state;

    return (
      <div className="TeamPage__pageContainer">
        <div className="TeamPage__textContainer">
          <h1 className="TeamPage__title">Meet the faces behind the event</h1>
          {isMobile
            ? fullText
              ? this.renderFullText()
              : this.renderHiddenText()
            : this.renderFullText()}
        </div>
        {isMobile ? this.renderTeamMobile() : this.renderTeamDesktop()}
      </div>
    );
  }

  renderHiddenText() {
    return (
      <div className="TeamPage__hiddenTextContainer">
        {this.renderFullText()}
        <div className="TeamPage__veil"></div>
        <Button
          className="TeamPage__button"
          onClick={this.handleClick}
          variant="transparent-gradient"
        >
          Read More
        </Button>
      </div>
    );
  }

  renderFullText() {
    return (
      <div className="TeamPage__descriptionContainer">
        <h2 className="TeamPage__purpleText">
          IEEE University of Porto Student Branch
        </h2>
        <p className="TeamPage__description">
          Our Student Branch (SB) is composed by University of Porto (UP)
          students and IEEE members, who have the passion of sharing knowledge
          and make activities that grant to our student community and also to
          technological advance. Our student members can develop soft-skills, by
          helping organize many of our activities and participate in activities
          organized by other SB, and hard-skills, by creating something you
          really want and participate in our development groups.
        </p>
        <p className="TeamPage__purpleText">
          Having had one of the best years so far at our Student Branch together
          with the motivation brought by some of our members having participated
          in previous ILS editions, we’ve set out to apply to host the next ILS
          and it is now our honor to do so! Take a look at the team behind this
          year’s ILS Congress.
        </p>
      </div>
    );
  }

  /**
   * Groups the team members by rows.
   * @param {Number} people_per_row
   * @return {[[{thumbnail, title, date, description}]]} An array, for each row, with a inner array of people data
   */
  groupPeopleByRows(people_per_row) {
    const people_row_array = [];
    let team_data = require('./team.json');
    while (team_data.length > people_per_row) {
      people_row_array.push(team_data.slice(0, people_per_row));
      team_data = team_data.slice(people_per_row);
    }
    people_row_array.push(team_data);
    return people_row_array;
  }

  /**
   * Renders the rows with people cards
   *
   * General structure:
   * <div className="TeamPage__peopleContainer">
   *      <div className="TeamPage__peopleRow">
   *          <PersonCard />
   *          <PersonCard />
   *      </div>
   *      <div className="TeamPage__peopleRow">
   *          <PersonCard />
   *          <PersonCard />
   *      </div>
   *  </div>
   *
   * @param {Number} people_per_row The number of people cards per row
   */
  renderTeam(people_per_row) {
    let i = 0;
    return (
      <div className="TeamPage__peopleContainer">
        {this.groupPeopleByRows(people_per_row).map(row => (
          <div className="TeamPage__peopleRow" key={`people_row_${i++}`}>
            {row.map(person => (
              <PersonCard
                key={`person_card_${i}`}
                name={person.name}
                title={person.position}
                source={require(`./../../assets/team_members/${person.photo_filename}`)}
              />
            ))}
          </div>
        ))}
      </div>
    );
  }

  renderTeamDesktop() {
    return this.renderTeam(4);
  }

  renderTeamMobile() {
    return this.renderTeam(2);
  }

  handleClick = () => {
    this.setState({ fullText: true });
  };
}
