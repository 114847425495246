import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dateValidator from './dateRegex';

export default class Countdown extends Component {
  static propTypes = {
    showMinutes: PropTypes.bool,
    to: PropTypes.string.isRequired
  };

  static defaultProps = {
    showMinutes: true
  };

  constructor(props) {
    super(props);

    this.state = {
      days: undefined,
      hours: undefined,
      minutes: undefined
    };
  }

  componentDidMount() {
    this.updateCountdown();
    this.interval = setInterval(this.updateCountdown, 1000);
  }

  componentWillUnmount() {
    this.interval && clearInterval(this.interval);
  }

  updateCountdown = () => {
    const { to, showMinutes } = this.props;

    if (!dateValidator.test(to)) {
      console.error("This component expects a 'dd/mm/yyyy'-shaped string");
      this.interval && clearInterval(this.interval);
      return;
    }

    const toComponents = to.split('/');
    const toDate = new Date(
      toComponents[2],
      toComponents[1] - 1,
      toComponents[0]
    );
    const now = Date.now();
    const timeDelta = Math.abs(toDate.getTime() - now);
    const days = Math.floor(timeDelta / 86400000);
    const hours = Math.floor((timeDelta % 86400000) / 3600000);
    const minutes = showMinutes
      ? Math.floor(((timeDelta % 86400000) % 3600000) / 60000)
      : undefined;

    this.setState({
      days,
      hours,
      minutes
    });
  };

  render() {
    const { showMinutes } = this.props;
    const { days, minutes, hours } = this.state;

    return (
      <div className="Countdown__container">
        <p className="Countdown__title">Starts in:</p>
        <div className="Countdown__numberContainer">
          <div className="Countdown__numberCard">
            <p className="Countdown__number">{days}</p>
            <p>Days</p>
          </div>
          <div className="Countdown__numberCard">
            <p className="Countdown__number">{hours}</p>
            <p>Hours</p>
          </div>
          {showMinutes && (
            <div className="Countdown__numberCard">
              <p className="Countdown__number">{minutes}</p>
              <p>Minutes</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
