import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';

export default class SpeakerModal extends Modal {
  constructor(props) {
    super(props);
  }

  renderSpeakerItem(speaker, render_abstracts, render_biography) {
    const { name, abstract, title, picture, bio } = speaker;
    console.log('abstract', abstract);
    return (
      <div className="SpeakerModal__Body__SpeakerItem">
        <div className="SpeakerItem__SpeakerInfo">
          <img
            className="SpeakerItem__SpeakerInfo__Photo"
            src={require(`../../assets/speakers/${picture}`)}
          />
          <span className="SpeakerItem__SpeakerInfo__Name">{name}</span>
        </div>
        <div className="SpeakerItem__TalkInfo">
          <h1 className="SpeakerItem__TalkInfo__Title">{title}</h1>
          {render_abstracts ? (
            <Fragment>
              <h2 className="SpeakerItem__TalkInfo__SectionHeader">Abstract</h2>
              {abstract ? (
                abstract
                  .split('\n')
                  .map(p => (
                    <p className="SpeakerItem__TalkInfo__Abstract">{p}</p>
                  ))
              ) : (
                <p className="SpeakerItem__TalkInfo__Abstract">Not available</p>
              )}
            </Fragment>
          ) : (
            ''
          )}
          {render_biography ? (
            <Fragment>
              <h2 className="SpeakerItem__TalkInfo__SectionHeader">
                Biography
              </h2>
              <p className="SpeakerItem__TalkInfo__Biography">
                {bio ? bio : 'Not available'}
              </p>
            </Fragment>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }

  render() {
    const { speakers, render_abstracts, render_biography } = this.props;

    return super.render(
      speakers.map((speaker, i) =>
        this.renderSpeakerItem(speaker, render_abstracts, render_biography)
      )
    );
  }
}

SpeakerModal.propTypes = {
  render_abstracts: PropTypes.bool,
  render_biography: PropTypes.bool
};
