import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Speakercard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false
    };
  }

  render() {
    const { picture, name, title, bio } = this.props;
    const { opened } = this.state;

    return (
      <div onClick={this.toggleCard} className="SpeakerCard__container">
        <div className="SpeakerCard__presentationContainer">
          <img
            src={require(`./../../assets/speakers/${picture}`)}
            className="SpeakerCard__image"
          />
          <div className="SpeakerCard__textContainer">
            <h1 className="SpeakerCard__name">{name}</h1>
            <h2 className="SpeakerCard__title">{title}</h2>
          </div>
        </div>
        {opened && <p className="SpeakerCard__bio">{bio}</p>}
      </div>
    );
  }

  toggleCard = () => {
    this.setState(({ opened }) => ({ opened: !opened }));
  };
}
