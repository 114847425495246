import React from 'react';
import Hero from './../../components/hero/hero.jsx';
import CountdownCard from './../../components/countdown-card';
import EventFeatures from './../../components/event-features';
import Tickets from './../../components/tickets';
import BannerDiscoverPorto from './../../components/banner-discover-porto/BannerDiscoverPorto';
import Schedule from './../../components/schedule';

export default class HomePage extends React.Component {
  render() {
    const { width } = this.props;
    return (
      <main>
        <Hero history={this.props.history} />
        <CountdownCard to="25/07/2019" showMinutes={width > 768} />
        <EventFeatures />
        <Schedule id="schedule" isMobile={width <= 768} />
        <Tickets
          id="ticketsComponent"
          isDesktopCarousel={width <= 1440}
          isMobile={width <= 880}
        />
        <BannerDiscoverPorto history={this.props.history} />
      </main>
    );
  }
}
