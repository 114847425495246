import React from 'react';

const PersonCard = ({ isMobile, source, name, title }) => (
  <div className="PersonCard__container">
    <div className="PersonCard__imageContainer">
      <img src={source} className="PersonCard__image" />
    </div>
    <h1 className="PersonCard__name">{name}</h1>
    <h2 className="PersonCard__title">{title}</h2>
  </div>
);

export default PersonCard;
