import React from 'react';
import PropTypes from 'prop-types';
import Banner from '../banner';
import Button from '../button';

export default class BannerDiscoverPorto extends React.Component {
  getPortoPromotion() {
    const about_intro =
      'Located alongside the Douro River, Porto is one of the oldest European centres and the gateway for a journey across the natural and cultural diversity of the region.';

    const about = (
      <span>
        Three times winner of the Best European Destination, Porto combines
        ancient monuments like the <i>Catedral do Porto</i>,{' '}
        <i>Igreja de São Francisco</i> and <i>Torre dos Clérigos</i> with new
        and modern buildings like <i>Casa da Música</i> and{' '}
        <i>Fundação de Serralves</i>.
      </span>
    );

    return (
      <section className="BannerDiscoverPorto__content">
        <h1 className="BannerDiscoverPorto__title">Discover Porto City</h1>
        <p className="BannerDiscoverPorto__about-intro">{about_intro}</p>
        <p className="BannerDiscoverPorto__about">{about}</p>
        <div className="BannerDiscoverPorto__buttons">
          <Button
            className="BannerDiscoverPorto__buttons__button Button_boldText"
            onClick={() => this.props.history.push('/venues')}
          >
            Meet the Venue
          </Button>
          <Button
            className="BannerDiscoverPorto__buttons__button Button_boldText"
            onClick={() => this.props.history.push('/venues')}
          >
            Book Hotel
          </Button>
        </div>
      </section>
    );
  }

  render() {
    const url =
      'http://i1.nyt.com/images/2016/01/31/travel/31HOURS/31HOURS-jumbo.jpg';
    return (
      <Banner
        className="BannerDiscoverPorto"
        img_url={url}
        content={this.getPortoPromotion.bind(this)}
        isReverse={true}
        onMobile="stack"
      ></Banner>
    );
  }
}
