import React from 'react';
import PropTypes from 'prop-types';

export default class Banner extends React.Component {
  render() {
    let { img_url, content, isReverse, onMobile } = this.props;

    let myClassname;

    if (isReverse) myClassname = 'Banner__container--reverse';
    else myClassname = 'Banner__container';

    if (onMobile === 'overlap') myClassname += ' Banner--mobile-overlap';
    else if (onMobile === 'stack') myClassname += ' Banner--mobile-stack';

    return (
      <section className={`${myClassname} ${this.props.className || ''}`}>
        <section className="Banner__container__content purple-gradient-bg">
          <div className="container">{content()}</div>
        </section>
        <section
          className="Banner__container__image"
          style={{ backgroundImage: 'url(' + img_url + ')' }}
        />
      </section>
    );
  }
}

Banner.propTypes = {
  img_url: PropTypes.string.isRequired,
  content: PropTypes.func.isRequired,
  isReverse: PropTypes.bool,
  onMobile: PropTypes.oneOf(['overlap', 'stack'])
};

Banner.defaultProps = {
  isReverse: false
};
