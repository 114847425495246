import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

const Button = ({ id, variant, onClick, children, className }) => (
  <Fragment>
    {variant !== 'transparent-gradient'
      ? renderRegularButtonVariants({
          id,
          variant,
          onClick,
          children,
          className
        })
      : renderGradientBorderButton({ id, onClick, children, className })}
  </Fragment>
);

const renderRegularButtonVariants = ({
  id,
  variant,
  onClick,
  children,
  className
}) => (
  <a
    id={id}
    onClick={onClick}
    className={`Button__button Button__${variant} ${
      className ? className : ''
    }`}
  >
    {children}
  </a>
);

const renderGradientBorderButton = ({ id, onClick, children, className }) => (
  <div
    id={id}
    className={`Button__linearBackground ${className ? className : ''}`}
  >
    <a
      onClick={onClick}
      className={`Button__button Button__transparentGradient`}
    >
      {children}
    </a>
  </div>
);

Button.propTypes = {
  variant: PropTypes.oneOf([
    'transparent',
    'fill-gradient',
    'fill-white',
    'transparent-gradient'
  ]),
  onClick: PropTypes.func
};

Button.defaultProps = {
  variant: 'transparent'
};

export default Button;
