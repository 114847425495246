import React, { Component } from 'react';

export default class SponsorTapPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="SponsorTapPage__pageContainer">
        <div className="SponsorTapPage__textContainer">
          <h1 className="SponsorTapPage__title">TAP Air Portugal</h1>
          {this.renderIntroduction()}
        </div>
        <section className="SponsorTapPage__promoImage"></section>
        <section className="SponsorTapPage__instructionsSection">
          <h2 className="SponsorTapPage__subHeader">Instructions</h2>
          <div className="SponsorTapPage__instructionsContainer">
            <div className="SponsorTapPage__instructionsContainer__guide">
              <p>
                The code given to this event must be mentioned when making the
                booking in order to benefit from the discount:
              </p>
              <ul>
                <li>
                  enter the website{' '}
                  <a href="https://www.flytap.com">flytap.com</a>
                </li>
                <li>select flights and dates</li>
                <li>
                  insert the event code in the Promotion/Congress Code Box,
                  located on the left side of the webpage
                </li>
              </ul>
            </div>
            <div className="SponsorTapPage__instructionsContainer__promoCode">
              <p className="promoCode__info">
                When booking use the following code:
              </p>
              <span className="promoCode__code">IT19TPCG137</span>
            </div>
          </div>
        </section>
        <section className="SponsorTapPage__importantInformationSection">
          <h2 className="SponsorTapPage__subHeader">Important Information</h2>
          <ul>
            <li>
              The discount applicable to this event is only valid for online
              bookings made through TAP Portugal’s website www.flytap.com and
              with payment by credit card. The ticket will always be an
              electronic ticket.
            </li>
            <li>
              Bookings made previously by travel agencies or at our desks are
              not eligible for this discount.
            </li>
            <li>
              The final price shown at the end of the booking is the final fare,
              including the discount and all related taxes. The discount is
              applicable only over the fare, excluding all taxes related to the
              voyage.
            </li>
            <li>
              The discount is not accumulative with fares for resident / student
              / child / infant /senior, etc.
            </li>
            <li>
              In case the website is down, Delegates should try again later as
              discount is only possible on website.
            </li>
            <li>
              The discount applies only for travel to the event. It is valid for
              round trip journeys from any TAP destination to Lisbon or Porto.
            </li>
            <li>
              The booking can be made at any time. The travel dates must be
              comprehended in the period of 10 days before / 10 days after the
              event dates. The dates of the event must be comprehended in the
              period of travel. The discount is also extended to the
              participants accompanying persons (adults).
            </li>
            <li>
              The conditions and restrictions regarding changes and refunds are
              those of the applicable fare. Please check the fare details before
              ending your booking.
            </li>
            <li>
              The discount only applies to TAP operated flights. Bookings on or
              which include code-share flights operated by other airlines or on
              other airlines do not benefit from the discount. Code-share
              flights are identified on online bookings with the message "OAL"
              or:{' '}
              <img
                className="SponsorTapPage__importantInformationSection__starAllianceLogo"
                src={require('../../../assets/star_alliance_icon.png')}
                alt=""
              />
            </li>
          </ul>
        </section>
      </div>
    );
  }

  renderDiscountCard(booking_class, discount) {
    return (
      <div className="SponsorTapPage__discountCard">
        <div className="SponsorTapPage__discountCard__percentage">
          {discount} %
        </div>
        <span className="SponsorTapPage__discountCard__bookingClass">
          {booking_class} Class
        </span>
      </div>
    );
  }

  renderIntroduction() {
    return (
      <div className="SponsorTapPage__descriptionContainer">
        <p className="SponsorTapPage__description">
          We are glad to announce that TAP Air Portugal, the flag carrier
          airline of Portugal, is partnering with the Women in Engineering
          International Leadership Summit in Porto, and has agreed to offer a
          discount to the participants who make their flight booking and buy
          their ticket exclusively through TAP Air Portugal’s website. For
          further details and instructions, see below.
        </p>
        <p className="SponsorTapPage__description">
          Depending on your booking class, you can benefit up to the following
          discounts:
        </p>
        <div className="SponsorTapPage__descriptionContainer__discounts">
          {this.renderDiscountCard('Economy', 10)}
          {this.renderDiscountCard('Bussiness', 15)}
        </div>
      </div>
    );
  }
}
