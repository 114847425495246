import React, { Component } from 'react';

import Tabs from '../tabs';
import ScheduleBlock from '../schedule-block';
import arrowIcon from '../../assets/arrow.svg';

export class Schedule extends Component {
  render() {
    const { id } = this.props;
    return (
      <div className="Schedule__container" id={id}>
        <h1 className="Schedule__title">Schedule</h1>
        <div className="Schedule__descriptionContainer">
          <p className="Schedule__description">
            Take a look at what is being prepared for you!
          </p>
          <p className="Schedule__description">
            This page is still being updated...
          </p>
        </div>
        <div className="Schedule__tabsContainer">
          <Tabs tabs={this.tabs} />
        </div>
      </div>
    );
  }

  firstDay = (
    <div className="Schedule__firstDayContainer">
      <div className="Schedule__block Schedule__fullDayBlock">
        <ScheduleBlock time="14:00 - 00:00" title="Registration" />
      </div>
      <div className="Schedule__block Schedule__sixHourBlock">
        <ScheduleBlock time="14:00 - 20:00" title="Periodic Walking Tours" />
      </div>
    </div>
  );

  secondDay = () => {
    const { isMobile } = this.props;

    return (
      <div className="Schedule__secondDayContainer">
        {!isMobile && (
          <div
            onClick={() => this.scrollBackward('track2')}
            className="Schedule__backwardScroller"
          >
            {'<'}
          </div>
        )}
        <div key="track2" id="track2" className="Schedule__track">
          <div className="Schedule__horizontalBlock Schedule__oneHourBlock">
            <ScheduleBlock time="09:00 - 10:00" title="Opening" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__ninetyMinuteBlock">
            <ScheduleBlock time="10:00 - 11:30" title="Leadership Panel" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__halfHourBlock">
            <ScheduleBlock time="11:30 - 12:00" title="Coffee Break" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__ninetyMinuteBlock">
            <ScheduleBlock time="12:00 - 13:30" title="Speakers Panel" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__ninetyMinuteBlock">
            <ScheduleBlock time="13:30 - 15:00" title="Lunch" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__oneHourBlock">
            <ScheduleBlock time="15:00 - 16:00" title="Visit Sessions" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__halfHourBlock">
            <ScheduleBlock time="16:00 - 16:30" title="Coffee Break" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__oneHourBlock">
            <ScheduleBlock time="16:30 - 17:30" title="Speakers Sessions" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__oneFiftyMinuteBlock">
            <ScheduleBlock time="17:30 - 20:00" title="Dress and Commute" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__fourHourBlock">
            <ScheduleBlock time="20:00 - 00:00" title="Gala Dinner" />
          </div>
        </div>
        {!isMobile && (
          <div
            onClick={() => this.scrollForward('track2')}
            className="Schedule__forwardScroller"
          >
            >
          </div>
        )}
        {isMobile && (
          <div className="Schedule__mobileControlsContainer">
            <img
              src={arrowIcon}
              onClick={() => this.scrollBackward('track2')}
              className="Schedule__backwardScroller Schedule__rotation"
            />
            <img
              src={arrowIcon}
              onClick={() => this.scrollForward('track2')}
              className="Schedule__forwardScroller"
            />
          </div>
        )}
      </div>
    );
  };

  thirdDay = () => {
    const { isMobile } = this.props;

    return (
      <div className="Schedule__thirdDayContainer">
        {!isMobile && (
          <div
            onClick={() => this.scrollBackward('track3')}
            className="Schedule__backwardScroller"
          >
            {'<'}
          </div>
        )}
        <div key="track3" id="track3" className="Schedule__track">
          <div className="Schedule__horizontalBlock Schedule__oneHourBlock">
            <ScheduleBlock time="09:00 - 10:00" title="Speakers Sessions" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__halfHourBlock">
            <ScheduleBlock time="10:00 - 10:30" title="Coffee Break" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__oneHourBlock">
            <ScheduleBlock time="10:30 - 11:30" title="Speakers Sessions" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__oneHourBlock">
            <ScheduleBlock time="11:30 - 12:30" title="Debate" />
          </div>
          <div className="Schedule__horizontalBlock Schdedule__twoHourBlock">
            <ScheduleBlock time="12:30 - 14:30" title="Lunch" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__ninetyMinuteBlock">
            <ScheduleBlock time="14:30 - 16:00" title="Equal Paths Panel" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__halfHourBlock">
            <ScheduleBlock time="16:00 - 16:30" title="Coffee Break" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__ninetyMinuteBlock">
            <ScheduleBlock time="16:30 - 18:00" title="Panel" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__oneHourBlock">
            <ScheduleBlock time="18:00 - 19:00" title="Closing Ceremony" />
          </div>
          <div className="Schedule__horizontalBlock Schedule__ninetyMinuteBlock">
            <ScheduleBlock
              time="19:00 - 20:30"
              title="Closing Reception w/ Port"
            />
          </div>
        </div>
        {!isMobile && (
          <div
            onClick={() => this.scrollForward('track3')}
            className="Schedule__forwardScroller"
          >
            >
          </div>
        )}
        {isMobile && (
          <div className="Schedule__mobileControlsContainer">
            <img
              src={arrowIcon}
              onClick={() => this.scrollBackward('track3')}
              className="Schedule__backwardScroller Schedule__rotation"
            />
            <img
              src={arrowIcon}
              onClick={() => this.scrollForward('track3')}
              className="Schedule__forwardScroller"
            />
          </div>
        )}
      </div>
    );
  };

  scrollForward = track => {
    const el = document.querySelector(`#${track}`);

    el && el.scroll(el.scrollLeft + 100, 0);
  };

  scrollBackward = track => {
    const el = document.querySelector(`#${track}`);

    el && el.scroll(el.scrollLeft - 100, 0);
  };

  tabs = [
    { title: '25 Jul', content: this.firstDay },
    { title: '26 Jul', content: this.secondDay() },
    { title: '27 Jul', content: this.thirdDay() }
  ];
}

export default Schedule;
