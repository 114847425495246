import React from 'react';
import Button from '../button';
import PropTypes from 'prop-types';

export default class TicketCard extends React.Component {
  buttonOnClick(url) {
    window.open(url);
  }

  getStyledButton() {
    let url = this.props.ticketLink;
    if (this.props.variant.localeCompare('basic') === 0) {
      return (
        <Button
          className="TicketCard__buyTicketBtn"
          variant="transparent-gradient"
          onClick={() => window.open(url)}
        >
          Buy Ticket
        </Button>
      );
    } else if (this.props.variant.localeCompare('premium') === 0) {
      return (
        <Button
          className="TicketCard__buyTicketBtn"
          variant="fill-white"
          onClick={() => window.open(url)}
        >
          Buy Ticket
        </Button>
      );
    }
  }

  render() {
    return (
      <div
        className={`TicketCard__container TicketCard__container--${this.props.variant}`}
      >
        <h1
          className={`TicketCard__title TicketCard__title--${this.props.variant}`}
        >
          {this.props.title}
        </h1>
        <strong
          className={`TicketCard__price TicketCard__price--${this.props.variant}`}
        >
          {this.props.price}€
        </strong>
        <ul
          className={`TicketCard__features TicketCard__features--${this.props.variant}`}
        >
          {this.props.description.map(feature => (
            <li className="TicketCard__features__item">{feature}</li>
          ))}
        </ul>
        {this.getStyledButton()}
      </div>
    );
  }
}

TicketCard.propTypes = {
  variant: PropTypes.oneOf(['premium', 'basic']).isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  ticketLink: PropTypes.string.isRequired
};
