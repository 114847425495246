import React from 'react';
import Button from '../button';
import Banner from '../banner';
import HeroImage from '../../assets/ponte_dao_luis.jpg';

export default class Hero extends React.Component {
  goToTickets() {
    if (document.getElementById('ticketsComponent')) {
      document.getElementById('ticketsComponent').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  getContent() {
    return (
      <section className="Hero__content">
        <img
          className="Hero__content__ils_porto_logo"
          src={require('../../assets/logo.svg')}
          alt="WIE ILS Porto Logo"
        />
        <h1>IEEE Women in Engineering International Leadership Summit</h1>
        <div className="Hero__content__btns">
          <Button
            onClick={() => this.props.history.push('/speakers')}
            className="Hero__content__btn-speakers Button_boldText"
          >
            Know the speakers
          </Button>
          <Button
            onClick={this.goToTickets.bind(this)}
            className="Hero__content__btn-register Button_boldText"
          >
            Register now
          </Button>
        </div>
        <div className="Hero__content__logos">
          <a
            className="Hero__content__ils_logo"
            href="https://wie.ieee.org/leadership-summits2019/"
            target="_blank"
          >
            <img
              src={require('../../assets/wie_ils_logo_white.svg')}
              alt="IEEE WIE ILS Logo"
            ></img>
          </a>
          <a
            className="Hero__content__wie_logo"
            href="https://wie.ieee.org/"
            target="_blank"
          >
            <img
              src={require('../../assets/wie_logo_white.svg')}
              alt="IEEE WIE logo"
            ></img>
          </a>
        </div>
        <span
          className="Hero__content__buy-tickets"
          onClick={this.goToTickets.bind(this)}
        >
          Buy Tickets
        </span>
      </section>
    );
  }

  render() {
    return (
      <Banner
        className="Hero"
        img_url={HeroImage}
        content={this.getContent.bind(this)}
        onMobile="overlap"
      />
    );
  }
}
