import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

export default class Tabs extends Component {
  static propTypes = {
    tabs: PropTypes.array.isRequired,
    gradient: PropTypes.bool
  };

  static defaultProps = {
    gradient: false
  };

  constructor(props) {
    super(props);

    const { tabs } = props;

    this.state = {
      activeTab: tabs.length > 0 ? 0 : undefined
    };
  }

  render() {
    const { tabs, gradient } = this.props;
    const tabNames = [];
    const tabContent = [];

    for (const tab of tabs) {
      tabNames.push(tab.title);
      tabContent.push(tab.content);
    }

    const { activeTab } = this.state;

    return (
      <div className="Tabs__container">
        <div className="Tabs__tabsContainer">
          {tabs.length && tabs.length > 0
            ? tabNames.map((name, index) => (
                <div className="Tabs__tabContainer">
                  <p
                    key={name}
                    className={`Tabs__tab${gradient ? 'Gradient' : ''}`}
                    onClick={() => this.setTab(index)}
                  >
                    {name}
                  </p>
                  {activeTab === index && (
                    <span
                      className={`Tabs__activeIndicator${
                        gradient ? 'Gradient' : ''
                      }`}
                    />
                  )}
                </div>
              ))
            : null}
        </div>
        <div className={`Tabs__contentContainer${gradient ? 'Gradient' : ''}`}>
          {tabContent[activeTab]}
        </div>
      </div>
    );
  }

  setTab(index) {
    this.setState({ activeTab: index });
  }
}
