import React, { Component } from 'react';

import Tabs from '../../components/tabs';
import Tracks from './tracks/Tracks';
import Schedule from './schedule';
import Visits from './visits';

export default class ProgramPage extends Component {
  visits = [
    {
      name: 'LAT - High Voltage Laboratory (Laboratório de Alta Tensão)',
      description:
        "It is the largest university laboratory with industrial dimension in Portugal.The scientific and technological area of the high voltages involves the study of the intense electric fields and the several physical-technological factors that require the cooperation of numerical simulation studies based on mathematical models (computational modeling) combined with studies based on laboratory experimentation (laboratory tests). Experimental studies involve testing in high voltage laboratories.\nRegarding equipments, LAT has different units for industrial frequency tests and a voltage generator.\nThe industrial frequency test system consists of four units, which are the transformer, voltage divider, regulator and controller. The Phenix brand transformer is a device that produces a maximum power of 300 kVA, reaching a maximum test voltage of 600 kV.\nThe LAT's Impulse Voltage Generator is a Haefely S-type equipment capable of producing a normalized fast forward wave of approximately 1200 kV.",
      url: 'https://paginas.fe.up.pt/~lat/index.html'
    },
    {
      name: 'Laboratory of Industrial Robotics and Automation',
      description:
        'The Industrial Robotics and Automation Laboratory: New Challenges for Manufacturing and Smart Production is focused on developing cognitive, sensitive, collaborative and safe robot-based automation solutions for manufacturing applications, which support digital transformation, smart sensing and man-machine interaction, and advanced control initiatives in industry.',
      url:
        'https://www.inesctec.pt/en/laboratories/laboratory-of-industrial-robotics-and-automation'
    }
  ];

  sessions = [
    {
      date: 'July 25th',
      time: '9:00 - 11:00',
      talks: [
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' },
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' },
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' }
      ]
    },
    {
      date: 'July 25th',
      time: '9:00 - 11:00',
      talks: [
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' },
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy lol' },
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' }
      ]
    },
    {
      date: 'July 25th',
      time: '9:00 - 11:00',
      talks: [
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' },
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' },
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' }
      ]
    }
  ];

  panels = [
    {
      date: 'July 26th',
      time: '10:00 - 11:30',
      talks: [
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' },
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' },
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' }
      ]
    },
    {
      date: 'July 25th',
      time: '9:00 - 11:00',
      talks: [
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' },
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy lol' },
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' }
      ]
    },
    {
      date: 'July 25th',
      time: '9:00 - 11:00',
      talks: [
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' },
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' },
        { name: 'Miguel Ramalho', title: 'Quantum Supremacy' }
      ]
    }
  ];

  render() {
    return (
      <div className="ProgramPage__container">
        <Tabs gradient tabs={this.tabs} />
      </div>
    );
  }

  renderVisits() {
    const { isMobile } = this.props;

    return (
      <div className="ProgramPage__contentContainer">
        <div className="ProgramPage__tracksTextContainer">
          <h1 className="ProgramPage__title">Explore FEUP's laboratories</h1>
          {!isMobile && (
            <p className="ProgramPage__description">
              These are the visits we have prepared for you. They will happen on
              the 26th, at 15h. This page is being updated daily, so stay tuned!
            </p>
          )}
        </div>
        <Visits visits={this.visits} isMobile={isMobile} />
      </div>
    );
  }

  renderSessions() {
    const { isMobile } = this.props;

    return (
      <div className="ProgramPage__contentContainer">
        <div className="ProgramPage__tracksTextContainer">
          <h1 className="ProgramPage__title">Explore FEUP's laboratories</h1>
          {!isMobile && (
            <p className="ProgramPage__description">
              These are the visits we have prepared for you. They will happen on
              the 26th, at 15h. This page is being updated daily, so stay tuned!
            </p>
          )}
        </div>
        <Tracks tracks={this.sessions} isMobile={isMobile} />
      </div>
    );
  }

  renderPanels() {
    const { isMobile } = this.props;

    return (
      <div className="ProgramPage__contentContainer">
        <div className="ProgramPage__tracksTextContainer">
          <h1 className="ProgramPage__title">Explore FEUP's laboratories</h1>
          {!isMobile && (
            <p className="ProgramPage__description">
              These are the visits we have prepared for you. They will happen on
              the 26th, at 15h. This page is being updated daily, so stay tuned!
            </p>
          )}
        </div>
        <Tracks tracks={this.panels} isMobile={isMobile} />
      </div>
    );
  }

  renderSchedule() {
    const { isMobile } = this.props;

    return (
      <div className="ProgramPage__contentContainer">
        <div className="ProgramPage__tracksTextContainer">
          <h1 className="ProgramPage__title">Take a look!</h1>
          {!isMobile && (
            <p className="ProgramPage__description">
              This is what we have prepared for you. This page is still being
              updated.
            </p>
          )}
        </div>
        <Schedule
          days={[
            { date: 'July 25th', dateAbridged: 'July 25', day: 'thursday' },
            { date: 'July 26th', dateAbridged: 'July 26', day: 'friday' },
            { date: 'July 27th', dateAbridged: 'July 27', day: 'saturday' }
          ]}
          isMobile={isMobile}
        />
      </div>
    );
  }

  tabs = [
    { title: 'Schedule', content: this.renderSchedule() },
    //    { title: 'Panels', content: this.renderPanels() },
    //    { title: 'Sessions', content: this.renderSessions() },
    { title: 'Visits', content: this.renderVisits() }
  ];
}
