import React, { Component, Fragment } from 'react';

export default class Visits extends Component {
  constructor(props) {
    super(props);

    const { isMobile } = this.props;

    this.state = {
      currentVisit: isMobile ? undefined : 0
    };
  }

  render() {
    const { isMobile } = this.props;

    return (
      <Fragment>
        {isMobile ? this.renderMobile() : this.renderDesktop()}
      </Fragment>
    );
  }

  renderDesktop() {
    const { currentVisit } = this.state;
    const { visits } = this.props;

    return (
      <div className="Visits__container">
        <div className="Visits__container__tabs">
          {visits.map((visit, index) => (
            <div
              className="Visits__session"
              key={index}
              onClick={() => this.handleClick(index)}
            >
              <h1
                className={`Visits__sessionTitle${
                  index === currentVisit ? 'Selected' : ''
                }`}
              >
                {visit.name}
              </h1>
            </div>
          ))}
        </div>
        <div className="Visits__container__info">
          {visits[currentVisit].description.split('\n').map(line => (
            <p>{line}</p>
          ))}
          <p>
            <a
              className="Visits__container__info__website"
              href={visits[currentVisit].url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit the website
            </a>
          </p>
        </div>
      </div>
    );
  }

  renderMobile() {
    const { visits } = this.props;
    const { currentVisit } = this.state;

    return (
      <div>
        {visits.map((visit, index) => (
          <Fragment>
            <div
              className="Visits__session"
              onClick={() => this.handleClick(index)}
            >
              <h1 className="Visits__sessionTitleSelected">{visit.name}</h1>
            </div>
            {index === currentVisit &&
              visits[currentVisit].description
                .split('\n')
                .map(line => <p>{line}</p>)}
            {index === currentVisit && (
              <p>
                <a
                  className="Visits__container__info__website"
                  href={visits[currentVisit].url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit the website
                </a>
              </p>
            )}
          </Fragment>
        ))}
      </div>
    );
  }

  toggleCard = () => {
    this.setState(({ opened }) => ({ opened: !opened }));
  };

  handleClick = newVisit => {
    const { isMobile } = this.props;
    const { currentVisit } = this.state;

    if (currentVisit === newVisit) {
      if (isMobile) {
        this.setState({ currentVisit: undefined });
      }
      return;
    }

    this.setState({ currentVisit: newVisit });
  };
}
