import React from 'react';
import PropTypes from 'prop-types';

const ScheduleBlock = ({ title, time }) => (
  <div className="ScheduleBlock__container">
    <p className="ScheduleBlock__time">{time}</p>
    <p className="ScheduleBlock__title">{title}</p>
  </div>
);

ScheduleBlock.propTypes = {
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ScheduleBlock;
