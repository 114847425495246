import React from 'react';
import { debounce } from 'lodash';
import { Route, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Helmet from 'react-helmet';
import LogoIcon from '../src/assets/logo.ico';
import Navbar from './components/navbar';
import Footer from './components/footer/footer.jsx';
import AboutPage from './pages/about';
import TeamPage from './pages/team';
import SponsorsPage from './pages/sponsors';
import SponsorTapPage from './pages/sponsors/tap';
import SpeakersPage from './pages/speakers';
import Homepage from './pages/homepage/';
import VenuesPage from './pages/venues/';
import PrivacyPolicy from './pages/privacy-policy/';
import './index.css';
import ProgramPage from './pages/program';

const anchorHashes = {
  tickets: 'ticketsComponent',
  schedule: 'schedule'
};

export default class App extends React.Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth
    };

    this.browserHistory = createBrowserHistory();
    this.browserHistory.listen(location => {
      // route changed, might need to update navbar color
      // TODO this is just forcing the 'render()'. Can we just re-render the children Navbar?
      this.setState(this.state);
      // parse the hash for this route
      const hash = location.hash.replace('#', '');
      // if the hash is known
      if (anchorHashes[hash]) {
        setTimeout(() => {
          // scroll into the element
          const element = document.getElementById(anchorHashes[hash]);
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth'
            });
          }
        }, 0);
      } else {
        window.scrollTo(0, 0);
      }
    });
  }

  /**
   * Add an event listener for page resize when the component is mounted
   */
  componentDidMount() {
    window.addEventListener('resize', this.debouncedHandleResize);
  }

  render() {
    let navbarMobileTrigger = 850;
    let navbar_variant;
    if (this.state.width <= 850 || window.location.pathname !== '/')
      navbar_variant = 'purple';
    else navbar_variant = 'light';

    return (
      <main>
        <Helmet>
          <title>WIE ILS Portugal</title>
          <link rel="shortcut icon" href={LogoIcon} />
        </Helmet>
        <Router history={this.browserHistory}>
          <Route
            render={props => (
              <Navbar
                {...props}
                variant={navbar_variant}
                isMobile={this.state.width <= navbarMobileTrigger}
              />
            )}
          />
          <Route
            exact
            path="/"
            render={props => <Homepage {...props} width={this.state.width} />}
          />
          <Route
            path="/about"
            render={props => <AboutPage {...props} width={this.state.width} />}
          />
          <Route
            path="/team"
            render={props => (
              <TeamPage
                {...props}
                isMobile={this.state.width < 768 ? true : false}
              />
            )}
          />
          <Route
            path="/sponsors"
            render={props => (
              <SponsorsPage {...props} width={this.state.width} />
            )}
          />
          <Route
            path="/speakers"
            render={props => (
              <SpeakersPage
                {...props}
                isMobile={this.state.width < 768 ? true : false}
              />
            )}
          />
          <Route
            path="/program"
            render={props => (
              <ProgramPage
                {...props}
                isMobile={this.state.width < 768 ? true : false}
              />
            )}
          />
          <Route
            path="/venues"
            render={props => (
              <VenuesPage
                {...props}
                isMobile={this.state.width < 768 ? true : false}
              />
            )}
          />
          <Route path="/privacy-policy" render={props => <PrivacyPolicy />} />
          <Route path="/sponsor/tap" render={props => <SponsorTapPage />} />
          <Footer />
        </Router>
      </main>
    );
  }

  handleResize = () => this.setState({ width: window.innerWidth });

  debouncedHandleResize = debounce(this.handleResize, 50);
}
