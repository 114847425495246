import React, { Component } from 'react';

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_state: false /* false for closed, true for open */
    };
  }

  toogleModalState() {
    this.setState({
      modal_state: !this.state.modal_state
    });
  }

  render(modal_body) {
    let modal_state = this.state.modal_state ? 'open' : 'closed';
    /* Disable scrolling in <body> if the modal is open */
    if (this.state.modal_state) {
      document.getElementsByTagName('body')[0].style['overflow-y'] = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style['overflow-y'] = 'auto';
    }

    return (
      <div className={'Modal Modal--' + modal_state}>
        <div className="Modal__Content">
          <div className="Modal__Header">
            <span
              className="Modal__Header__Close"
              onClick={this.toogleModalState.bind(this)}
            >
              &times;
            </span>
          </div>
          <div className="Modal__Body">{modal_body}</div>
        </div>
      </div>
    );
  }
}
