import React from 'react';
import { Fragment } from 'react';
import VenuesCard from '../../components/venues-card';

export default class VenuesPage extends React.Component {
  constructor(props) {
    super(props);

    // enumerator for the possible states
    this.tabs = {
      CONFERENCE: 0,
      GALA_DINNER: 1,
      HOTELS: 2
    };

    this.state = {
      activeTab: 0
    };
  }

  renderConference() {
    return (
      <Fragment>
        <img
          className="Venues__ContentContainer__Image"
          src={require('./../../assets/venues/feup.jpg')}
          alt="FEUP campus"
        />
        <div className="Venues__ContentContainer__Content">
          <h1 className="Venues__ContentContainer__Header">FEUP</h1>
          <h2 className="Venues__ContentContainer__SubHeader">Conference</h2>
          <div className="Venues__ContentContainer__Info">
            <p className="Venues__ContentContainer__Info__p">
              The Women in Engineering International Leadership Summit, Porto
              2019, will take place at the Faculty of Engineering of the
              University of Porto, also known as FEUP. Talks, visits, debate,
              and lunches will happen at FEUP.
            </p>
            <p className="Venues__ContentContainer__Info__p">
              The Faculty of Engineering of the University of Porto undertakes
              activities in the realms of education, research, and innovation at
              an international level. Accordingly, the results of these
              activities lead to the creation and transmission of knowledge,
              training of competent and ethical professionals, and future
              leaders in the area of engineering and similar areas, and also the
              promotion of the wellbeing of our global society.
            </p>
            <p className="Venues__ContentContainer__Info__p">
              <b>Location:</b> R. Dr. Roberto Frias, 4200-465 Porto, Pólo II
            </p>
          </div>
          <iframe
            className="Venues__ContentContainer__Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1785.597253318796!2d-8.596886818900604!3d41.17806558905029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8b1e4a0bcdacc840!2sFEUP+-+Faculdade+de+Engenharia+da+Universidade+do+Porto!5e0!3m2!1spt-PT!2spt!4v1560968236520!5m2!1spt-PT!2spt"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </Fragment>
    );
  }

  renderGalaDinner() {
    return (
      <Fragment>
        <img
          className="Venues__ContentContainer__Image"
          src={require('./../../assets/venues/facm.jpg')}
          alt="FEUP campus"
        />
        <div className="Venues__ContentContainer__Content">
          <h1 className="Venues__ContentContainer__Header">Gala Dinner</h1>
          <h2 className="Venues__ContentContainer__SubHeader">
            Foundation Dr. António Cupertino de Miranda
          </h2>
          <div className="Venues__ContentContainer__Info">
            <p className="Venues__ContentContainer__Info__p">
              <i>Foundation Dr. António Cupertino de Miranda</i> is an
              institution whose primary mission is to promote educational,
              social and cultural activities. It is also home of the{' '}
              <i>Museu do Papel Moeda</i>, a national museum dedicated to the
              Portuguese paper currency.
            </p>
            <p className="Venues__ContentContainer__Info__p">
              During the night of the 26th of July, this foundation will host
              our congress' gala dinner.
            </p>
            <p className="Venues__ContentContainer__Info__p">
              Besides all networking and culinary experience, the{' '}
              <i>IEEE Region 8 Women in Engineering Awards</i> for the Year 2019
              will also take place here.
            </p>
            <p className="Venues__ContentContainer__Info__p">
              <b>Location:</b> Avenida da Boavista 4245, 4100–140 Porto
            </p>
          </div>
          <iframe
            className="Venues__ContentContainer__Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3003.6115206413647!2d-8.675744784581106!3d41.16482747928529!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246f6fcf92cb23%3A0x4a8037fd1c363f5d!2sFunda%C3%A7%C3%A3o+Dr.+Ant%C3%B3nio+Cupertino+de+Miranda!5e0!3m2!1spt-PT!2spt!4v1561024840143!5m2!1spt-PT!2spt"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </Fragment>
    );
  }

  renderHotels() {
    return (
      <Fragment>
        <img
          className="Venues__ContentContainer__Image"
          src={require('./../../assets/venues/hotels.jpg')}
          alt="FEUP campus"
        />
        <div className="Venues__ContentContainer__Content">
          <h1 className="Venues__ContentContainer__Header">Accommodations</h1>
          <p>
            Looking for a place where you can sleep through the event? The
            hotels presented below are our partners. As an attendee, you hold
            access to special benefits, such as discounts in your stay.
          </p>

          <h2>Ibis São João</h2>
          <div className="Venues__ContentContainer__Info">
            <p className="Venues__ContentContainer__Info__p">
              <ul>
                <li>Individual rooms: 72€</li>
                <li>Double rooms: 78€</li>
                <li>Prices include breakfast and taxes</li>
              </ul>
            </p>
            <p className="Venues__ContentContainer__Info__p">
              Visit the hotel website:
              <a
                className="Venues__ContentContainer__Info__Hotel"
                href="https://www.accorhotels.com/gb/hotel-3227-ibis-porto-sao-joao/index.shtml"
                target="_blank"
                rel="noopener noreferrer"
              >
                accordhotels.com
              </a>
            </p>
            <p className="Venues__ContentContainer__Info__p">
              <b>Location:</b> Rua Dr. Placido Costa, 4200-450 Porto, Portugal
            </p>
          </div>

          <h2>Eurostars</h2>
          <div className="Venues__ContentContainer__Info">
            <p className="Venues__ContentContainer__Info__p">
              <ul>
                <li>Discount: 10%</li>
              </ul>
            </p>
            <p className="Venues__ContentContainer__Info__p">
              Visit the hotel website:
              <a
                className="Venues__ContentContainer__Info__Hotel"
                href="https://www.eurostarshotels.co.uk/eurostars-oporto.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                eurostarshotels.co.uk
              </a>
            </p>
            <p className="Venues__ContentContainer__Info__p">
              <b>Location:</b> Rua do Mestre Guilherme Camarinha, 212, Paranhos,
              4200-537 Porto, Portugal
            </p>
          </div>
        </div>
      </Fragment>
    );
  }

  renderContent() {
    const isMobile = this.props.isMobile;

    let content;
    if (this.state.activeTab === this.tabs.CONFERENCE)
      content = this.renderConference();
    else if (this.state.activeTab === this.tabs.GALA_DINNER)
      content = this.renderGalaDinner();
    else if (this.state.activeTab === this.tabs.HOTELS)
      content = this.renderHotels();

    return (
      <div className="Venues__Container">
        <section className="Venues__ItemsContainer">
          <VenuesCard
            className="Venues__ItemsContainer__Card"
            active={
              this.state.activeTab === this.tabs.CONFERENCE ? true : false
            }
            text="Conference"
            light_icon="ic-conference.svg"
            active_icon="ic-conference-white.svg"
            content={isMobile ? this.renderConference() : ''}
            onClick={() => this.setState({ activeTab: this.tabs.CONFERENCE })}
          />
          <VenuesCard
            className="Venues__ItemsContainer__Card"
            active={
              this.state.activeTab === this.tabs.GALA_DINNER ? true : false
            }
            text="Gala"
            light_icon="ic-gala.svg"
            active_icon="ic-gala-white.svg"
            content={isMobile ? this.renderGalaDinner() : ''}
            onClick={() => this.setState({ activeTab: this.tabs.GALA_DINNER })}
          />
          <VenuesCard
            className="Venues__ItemsContainer__Card"
            active={this.state.activeTab === this.tabs.HOTELS ? true : false}
            text="Hotels"
            light_icon="ic-hotel.svg"
            active_icon="ic-hotel-white.svg"
            content={isMobile ? this.renderHotels() : ''}
            onClick={() => this.setState({ activeTab: this.tabs.HOTELS })}
          />
        </section>
        {!isMobile ? (
          <section className="Venues__ContentContainer">{content}</section>
        ) : (
          ''
        )}
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <section className="container">
          <h1 className="Venues__Title">Where will I spend my time?</h1>
          {this.renderContent()}
        </section>
      </Fragment>
    );
  }
}
