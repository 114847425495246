import React, { Fragment } from 'react';
import Button from '../button';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * Maps the navbar item names to the respective url
 */
const navbarItems = {
  Home: '/',
  Speakers: '/speakers',
  Venues: '/venues',
  'Sponsors & Patrons': '/sponsors',
  About: '/about',
  Program: '/program',
  Team: '/team'
};

/**
 * Represents a navbar item
 */
class NavItem extends React.Component {
  render() {
    const { variant, url, name } = this.props;
    return (
      <li
        onClick={this.props.onClick}
        className={`navbar__item navbar__item--${variant}`}
      >
        <Link to={url}>{name}</Link>
      </li>
    );
  }
}

/**
 * Represents the website navbar, common to all pages
 */
export default class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navMenuShowing: false
    };
  }

  componentDidUpdate() {
    if (!this.props.isMobile) {
      document.body.style['overflowY'] = 'auto';
    } else {
      if (this.state.navMenuShowing) {
        document.body.style['overflowY'] = 'hidden';
      } else {
        document.body.style['overflowY'] = 'auto';
      }
    }
  }

  render() {
    const { isMobile, variant } = this.props;
    return (
      <nav className={`navbar navbar--${variant}`}>
        <div className="container navbar__content">
          {isMobile ? this.renderMobile() : this.renderDesktop()}
        </div>
      </nav>
    );
  }

  renderMobile = () => {
    const { navMenuShowing } = this.state;
    const facebook = 'https://www.facebook.com/wie.ils.pt/';
    const instagram = 'https://www.instagram.com/wie.ils.pt/';
    const twitter = 'https://twitter.com/wie_ils_pt';
    return (
      <Fragment>
        <p
          className={`navbar__menuTrigger--${this.props.variant}`}
          onClick={this.toggleNavMenu}
        >
          Menu
        </p>
        <div
          className={`navbar__menuContent ${
            navMenuShowing
              ? 'navbar__menuContentShowing'
              : 'navbar__menuContentHidden'
          }`}
        >
          <div className="navbar__closeMenuContainer">
            <p className="navbar__closeMenu" onClick={this.toggleNavMenu}>
              Close
            </p>
          </div>
          <ul className="navbar__items">
            {Object.keys(navbarItems).map(key => {
              return (
                <NavItem
                  onClick={() => {
                    this.toggleNavMenu();
                  }}
                  key={key}
                  name={key}
                  url={navbarItems[key]}
                  variant={this.props.variant}
                />
              );
            })}

            <NavItem
              onClick={() => {
                window.location.href =
                  'https://wieils.ieee-pt.org/docs/booklet.pdf';
              }}
              key="Booklet"
              name="Digital Booklet"
              url="#"
              variant={this.props.variant}
            />
          </ul>
          <div className="navbar__socialMediaContainer">
            <p className="navbar__socialMediaTitle">Find us here:</p>
            <div className="navbar__iconContainer">
              <a className="navbar__iconContainer__icon" href={facebook}>
                <i className="fab fa-facebook"></i>
              </a>
              <a className="navbar__iconContainer__icon" href={instagram}>
                <i className="fab fa-instagram"></i>
              </a>
              <a className="navbar__iconContainer__icon" href={twitter}>
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </div>
          <div className="navbar__menuBottomInfo">
            <p>Privacy Policy</p>
            <p>Copyright © all rights reserved</p>
            <p>IEEE WiE</p>
          </div>
        </div>
      </Fragment>
    );
  };

  renderDesktop = () => (
    <Fragment>
      <Button variant="fill-white" className="navbar__ticketsBtn">
        Buy Tickets
      </Button>
      <ul className="navbar__items">
        {Object.keys(navbarItems).map(key => {
          return (
            <NavItem
              key={key}
              name={key}
              url={navbarItems[key]}
              variant={this.props.variant}
            />
          );
        })}
      </ul>
      <Button
        variant={
          this.props.variant.localeCompare('light')
            ? 'fill-gradient'
            : 'fill-white'
        }
        className="navbar__ticketsBtn"
        onClick={() =>
          (window.location.href = 'https://wieils.ieee-pt.org/docs/booklet.pdf')
        }
      >
        Digital Booklet
      </Button>
    </Fragment>
  );

  toggleNavMenu = () => {
    const { navMenuShowing } = this.state;

    if (navMenuShowing) {
      document.body.style['overflowY'] = 'auto';
    } else {
      document.body.style['overflowY'] = 'hidden';
    }

    this.setState(state => ({ navMenuShowing: !state.navMenuShowing }));
  };
}

Navbar.propTypes = {
  variant: PropTypes.oneOf(['purple', 'light']),
  isMobile: PropTypes.bool.isRequired
};
