import React from 'react';
import Button from './../../components/button';
import PastEventCard from './../../components/past-event-card';
import { Fragment } from 'react';
import EventsData from './events.json';

export default class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: true };
  }

  renderMobileCollapsedAbout() {
    return (
      <Fragment>
        <div id="description_fadeout" className="description__fade-out"></div>
        <div
          id="read_more_container"
          className="description__read-more--collapsed"
        >
          <Button
            className="description__read-more__button"
            id="read_more_btn"
            variant="transparent-gradient"
            onClick={() => this.readMoreClickHandler()}
          >
            Read More
          </Button>
        </div>
      </Fragment>
    );
  }

  renderMobileBlockAbout() {
    return (
      <Fragment>
        <div id="read_more_container" className="description__read-more">
          <Button
            id="read_more_btn"
            className="description__read-more__button"
            variant="transparent-gradient"
            onClick={() => this.readMoreClickHandler()}
          >
            Show less
          </Button>
        </div>
      </Fragment>
    );
  }

  renderReadMoreSection() {
    if (this.state.collapsed) {
      return this.renderMobileCollapsedAbout();
    } else {
      return this.renderMobileBlockAbout();
    }
  }

  readMoreClickHandler() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    let isMobile = this.props.width <= 768;
    if (!isMobile) this.state = { collapsed: false };
    return (
      <div className="container">
        <div className="about-container">
          <h1 className="about-container__title">IEEE WIE ILS</h1>
          <div
            id="description_container"
            className={
              'about-container__description' +
              (isMobile && this.state.collapsed ? '--collapsed' : '')
            }
          >
            <p className="description__paragraph">
              IEEE Women in Engineering International Leadership Summits (WIE
              ILS) provide regional opportunities to foster networking,
              mentorship, and collaboration.
            </p>
            <p className="description__paragraph">
              IEEE Women in Engineering (WIE) is one of the largest
              international professional organizations dedicated to promoting
              women engineers and scientists, and inspiring girls around the
              world to follow their academic interests in a career in
              engineering. Visit{' '}
              <a href="https://wie.ieee.org">https://wie.ieee.org.</a>
            </p>
            <p className="description__paragraph description__paragraph--emphasis">
              The mission of IEEE WIE is to facilitate the recruitment and
              retention of women in technical disciplines globally.
            </p>
            {isMobile ? this.renderReadMoreSection() : ''}
          </div>
        </div>

        <div className="past-events-container">
          <h1 className="past-events-container__title">Past Events</h1>
          {EventsData.map(event => (
            <PastEventCard
              width={this.props.width}
              thumbnail={require(`./../../assets/past_events/${event.thumbnail}`)}
              title={event.title}
              date={event.date}
              description={event.description}
            />
          ))}
        </div>
      </div>
    );
  }
}
