import React, { Fragment } from 'react';

import Tabs from '../../components/tabs';
import SpeakerCard from '../../components/speaker-card';

const speakersList = require('./speakers.json');

const renderSpeakers = speakers => (
  <div className="SpeakersPage__tabContainer">
    {speakers.map(speaker => (
      <div className="SpeakersPage__speakerContainer">
        <img
          className="SpeakersPage__image"
          src={require(`./../../assets/speakers/${speaker.picture}`)}
        />
        <div className="SpeakersPage__infoContainer">
          <h1 className="SpeakersPage__speakerName">{speaker.name}</h1>
          <h2 className="SpeakersPage__speakerTitle">{speaker.title}</h2>
          {speaker.bio.split('\n').map(paragraph => {
            return <p className="SpeakersPage__speakerBio">{paragraph}</p>;
          })}
        </div>
      </div>
    ))}
  </div>
);

const renderSpeakersMobile = speakers => (
  <Fragment>
    {speakers.map(speaker => (
      <SpeakerCard {...speaker} />
    ))}
  </Fragment>
);

const getTabs = isMobile => [
  {
    title: '26 Jul',
    content: isMobile
      ? renderSpeakersMobile(speakersList.day_1)
      : renderSpeakers(speakersList.day_1)
  },
  {
    title: '27 Jul',
    content: isMobile
      ? renderSpeakersMobile(speakersList.day_2)
      : renderSpeakers(speakersList.day_2)
  }
];

const SpeakersPage = ({ isMobile }) => (
  <div className="SpeakersPage__container">
    <h1 className="SpeakersPage__title">Meet our great speakers</h1>
    <p style={{ textAlign: 'center' }}>More to be announced soon!</p>
    <div className="SpeakersPage__speakersContainer">
      <Tabs gradient tabs={getTabs(isMobile)} />
    </div>
  </div>
);

export default SpeakersPage;
