import React from 'react';
import PropTypes from 'prop-types';

const FeatureCard = ({ sources, title, text }) => (
  <div className="FeatureCard__container">
    <div className="FeatureCard__imageContainer">
      <img className="FeatureCard__image" src={sources} />
    </div>
    <h1 className="FeatureCard__title">{title}</h1>
    <p className="FeatureCard__text">{text}</p>
  </div>
);

FeatureCard.propTypes = {
  sources: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default FeatureCard;
