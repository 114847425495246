import React, { Component, Fragment } from 'react';
import SpeakerModal from '../../../components/speaker-modal';
import PanelModal from '../../../components/panel-modal';

// the speakers information
const speakers_data = require('../../speakers/speakers.json');
// the program information
const schedule_data = require('./schedule.json');

export default class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDay: 0
    };
  }

  render() {
    const { isMobile } = this.props;
    const { currentDay } = this.state;

    return (
      <div className="ProgramSchedule__container">
        {isMobile ? this.renderDaysMobile() : this.renderDaysDesktop()}
        <div className="ProgramSchedule__schedulesContainer">
          {((isMobile && currentDay === 0) || !isMobile) &&
            this.renderFirstDay()}
          {((isMobile && currentDay === 1) || !isMobile) &&
            this.renderSecondDay()}
          {((isMobile && currentDay === 2) || !isMobile) &&
            this.renderThirdDay()}
        </div>
      </div>
    );
  }

  renderDaysDesktop() {
    const { days } = this.props;
    const { currentDay } = this.state;

    return (
      <div className="ProgramSchedule__indexContainer">
        {days.map((day, index) => (
          <div
            className="ProgramSchedule__day"
            key={index}
            onClick={() => this.handleClick(index)}
          >
            <h1
              className={`ProgramSchedule__dayTitle${
                index === currentDay ? 'Selected' : ''
              }`}
            >
              {day.date}
            </h1>
            <p
              className={`ProgramSchedule__dayDate${
                index === currentDay ? 'Selected' : ''
              }`}
            >
              {day.day}
            </p>
          </div>
        ))}
      </div>
    );
  }

  renderDaysMobile() {
    const { days } = this.props;
    const { currentDay } = this.state;

    return (
      <div className="ProgramSchedule__indexContainerAbsolute">
        {days.map((day, index) => (
          <div
            className="ProgramSchedule__day"
            key={index}
            onClick={() => this.handleClick(index)}
          >
            <h1
              className={`ProgramSchedule__dayTitle${
                index === currentDay ? 'Selected' : ''
              }`}
            >
              {day.dateAbridged}
            </h1>
          </div>
        ))}
      </div>
    );
  }

  handleProgramBlockClick(ref) {
    if (ref !== null && ref !== undefined) ref.current.toogleModalState();
  }

  getBlockSpeakers(speakers_id) {
    let speakers_info = []; /* used to store the desired speakers data */
    for (let day in speakers_data) {
      speakers_data[day].forEach(speaker => {
        if (speakers_id.includes(speaker.id)) speakers_info.push(speaker);
      });
    }
    return speakers_info;
  }

  /**
   * Picks the correct class name for a give program block. These styling rules determine the height of these blocks
   */
  getBlockClassName(start_at, end_at) {
    let start_date = new Date('2019-01-01 ' + start_at);
    let end_date =
      end_at == '00:00'
        ? new Date('2019-01-02 ' + end_at)
        : new Date('2019-01-01 ' + end_at);
    let diff = (end_date - start_date) / 1000 / 60; // in minutes
    switch (diff) {
      case 30:
        return 'ProgramSchedule__blockHalfHour';
      case 60:
        return 'ProgramSchedule__blockOneHour';
      case 90:
        return 'ProgramSchedule__blockNinetyMinutes';
      case 120:
        return 'ProgramSchedule__blockTwoHours';
      case 150:
        return 'ProgramSchedule__blockOneFiftyMinutes';
      case 240:
        return 'ProgramSchedule__blockFourHours';
      case 360:
        return 'ProgramSchedule__blockSixHours';
      case 600:
        return 'ProgramSchedule__blockTenHours';
      default:
        throw `Unexpected block time: ${diff} minutes`;
    }
  }

  /**
   * Renders program blocks and attaches modals for blocks with speakers
   * @param block {start_at: String, end_at: String, title: String: speakers: String[]} An object with information regarding a session of the event. Contains title, start and end time, and if applicable an array of speakers
   * @param is_active_day Boolean If the program blocks should be active
   * @return The program block Node
   */
  renderProgramBlock(block, is_active_day) {
    // if this program block has speakers, the create a React reference
    let modalRef = block.speakers ? React.createRef() : null;

    return (
      <Fragment>
        {// if the program block has speakers, and there's a panel abstract, then this is a panel with multiple speakers
        // create a modal to present the panel topic and list of speakers participating
        block.speakers && block.panel_abstract ? (
          <PanelModal
            ref={modalRef}
            speakers={this.getBlockSpeakers(block.speakers)}
            panel_abstract={block.panel_abstract}
            title={block.title}
          />
        ) : (
          ''
        )}

        {// if the program block has speakers, but no panel abstract, then this program block has individual sessions for each speaker
        // create a modal to present full information regarding them and the talk itself
        block.speakers && !block.panel_abstract ? (
          <SpeakerModal
            ref={modalRef}
            speakers={this.getBlockSpeakers(block.speakers)}
            render_abstracts={true}
            render_biography={false}
          />
        ) : (
          ''
        )}

        <div
          className={`ProgramSchedule__block ${this.getBlockClassName(
            block.start_at,
            block.end_at
          )} 
          ${
            is_active_day && block.speakers
              ? ' ProgramSchedule__block--clickable'
              : ''
          }
          ${block.is_premium ? ' ProgramSchedule__gradient' : ''}`}
          onClick={
            is_active_day ? () => this.handleProgramBlockClick(modalRef) : null
          }
        >
          <p>
            {block.start_at} - {block.end_at}
          </p>
          <h1 className="ProgramSchedule__blockTitle">
            {block.title}{' '}
            {block.speakers ? (
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            ) : (
              ''
            )}
          </h1>
        </div>
      </Fragment>
    );
  }

  /**
   * Handles the rendering of all program blocks for a given day
   * @param program_day_schedule An array of sessions, where each session has a title, start and end times and array of speakers id if applicable
   * @param is_active_day If the blocks being rendered should be active
   */
  renderProgramBlocks(program_day_schedule, is_active_day) {
    return program_day_schedule.map(
      block => this.renderProgramBlock(block, is_active_day),
      this
    );
  }

  renderFirstDay() {
    const { currentDay } = this.state;

    return (
      <div
        className={`ProgramSchedule__dayContainer ProgramSchedule__concurrent ${currentDay ===
          0 && 'ProgramSchedule__active'}`}
      >
        <div className="ProgramSchedule__blockHalfWidth">
          {this.renderProgramBlocks(
            schedule_data.day_1_half1,
            currentDay === 0
          )}
        </div>
        <div className="ProgramSchedule__blockHalfWidth">
          {this.renderProgramBlocks(
            schedule_data.day_1_half2,
            currentDay === 0
          )}
        </div>
      </div>
    );
  }

  renderSecondDay() {
    const { currentDay } = this.state;
    console.log(schedule_data);
    return (
      <div
        className={`ProgramSchedule__dayContainer ${currentDay === 1 &&
          'ProgramSchedule__active'}`}
      >
        <div className="ProgramSchedule__blockFullWidth">
          {this.renderProgramBlocks(schedule_data.day_2, currentDay === 1)}
        </div>
      </div>
    );
  }

  renderThirdDay() {
    const { currentDay } = this.state;

    return (
      <div
        className={`ProgramSchedule__dayContainer ${currentDay === 2 &&
          'ProgramSchedule__active'}`}
      >
        <div className="ProgramSchedule__blockFullWidth">
          {this.renderProgramBlocks(schedule_data.day_3, currentDay === 2)}
        </div>
      </div>
    );
  }

  handleClick = newDay => {
    const { isMobile } = this.props;
    const { currentDay } = this.state;

    if (currentDay === newDay) {
      if (isMobile) {
        this.setState({ currentDay: undefined });
      }
      return;
    }

    this.setState({ currentDay: newDay });
  };
}
