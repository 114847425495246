import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';

export default class PanelModal extends Modal {
  constructor(props) {
    super(props);
  }

  renderSpeakerItem(speaker, key) {
    const { name, picture, bio } = speaker;

    return (
      <div key={key} className="PanelModal__SpeakersContainer__Speaker">
        <div className="PanelModal__Speaker">
          <img
            className="PanelModal__Speaker__Photo"
            src={require(`../../assets/speakers/${picture}`)}
          />
          <span className="PanelModal__Speaker__Name">{name}</span>
        </div>
      </div>
    );
  }

  render() {
    const { title, panel_abstract, speakers } = this.props;
    console.log(panel_abstract);
    return super.render(
      <div className="PanelModal">
        <h1 className="PanelModal__Title">{title}</h1>
        <h2 className="PanelModal__SubTitle">Description</h2>
        <p className="PanelModal__Description">{panel_abstract}</p>
        <div className="PanelModal__SpeakersContainer">
          {speakers.map((speaker, i) => this.renderSpeakerItem(speaker, i))}
        </div>
      </div>
    );
  }
}

PanelModal.propTypes = {
  title: PropTypes.string,
  speakers: PropTypes.array,
  panel_abstract: PropTypes.string
};
