import React from 'react';
import { Fragment } from 'react';

export default class PrivacyPolicy extends React.Component {
  renderPurpose() {
    return (
      <Fragment>
        <h2>1. Purpose and Scope of Notice</h2>
        <p>
          This Privacy Notice ("<strong>Notice</strong>") is intended to explain
          how your personal information will be handled by the International
          Leadership Summit Team ("<strong>ILS Team</strong>" "
          <strong>we</strong>", "<strong>our</strong>" and "<strong>us</strong>
          ") of R. Dr. Roberto Frias s/n, 4200-465 Porto, Portugal and sets out
          the information including the personal information detailed below
          relating to you ("<strong>Personal Data</strong>") that will be
          collected and processed by the Team and/or on its behalf by its third
          party service providers in the context of your engagement with
          wieilsportugal@gmail.com (the "<strong>Email</strong>") and the ticket
          and payment platforms’ services ("<strong>Tito Services</strong>" and
          "<strong>Stripe Services</strong>").
        </p>
        <p>
          For the purposes of this Notice, the controller of your Personal Data
          is the ILS Team. If you have any questions or concerns about this
          Notice, please contact our Data Protection Representative André
          Cipriano Sousa who can be contacted at{' '}
          <a href="mailto:wieilsportugal+privacy@gmail.com">
            wieilsportugal+privacy@gmail.com
          </a>
          .
        </p>
      </Fragment>
    );
  }

  renderNoticeApplication() {
    return (
      <Fragment>
        <h2>2. APPLICATION OF THIS NOTICE</h2>
        <p>
          <strong>
            IMPORTANT: Please note that this Notice, while intended to be as
            complete and accurate as reasonably possible, is not exhaustive and
            may be updated from time to time in accordance with Section 9 of
            this Notice.
          </strong>
        </p>
        <p>
          This Notice applies to the way we collect and process your Personal
          Data. Personal Data will be collected and processed during our
          relationship with you and for a period afterwards as may be required
          for statistical purposes.
        </p>
        <p>
          During your dealings with us, we will collect Personal Data for
          example when you communicate with us, sign-up to the Tito Services,
          work with us or supply us with services, when you supply Personal Data
          through the Tito Services, submit an enquiry or request support or
          when you subscribe to or express an interest in any of our mailing
          lists.
        </p>
      </Fragment>
    );
  }

  renderPersonalData() {
    return (
      <Fragment>
        <h2>3. WHAT PERSONAL DATA WE PROCESS</h2>
        <p>We may collect and process the following Personal Data:</p>
        <h3>Personal Information</h3>
        <p>
          This includes information such as your name, email address, phone
          number, IEEE number if applicable and dietary habits.
        </p>
        <h3>Transactional History</h3>
        <p>
          This includes information about the date, time, value and number of
          transactions you make through the Tito Services and Stripe Services.
        </p>
        <h3>Miscellaneous</h3>
        <p>
          This includes any other information which is provided to us by you or
          on your behalf.
        </p>
      </Fragment>
    );
  }

  renderPersonalDataPurpose() {
    return (
      <Fragment>
        <h2>4. WHY WE PROCESS YOUR PERSONAL DATA</h2>
        <p>
          The following table details the legal bases for which ("
          <strong>Legal Basis</strong>") and the reasons why ("
          <strong>Purposes</strong>") we collect, obtain and process your
          Personal Data:
        </p>
        <table>
          <thead>
            <tr>
              <th>Legal Basis</th>
              <th>Purpose(s)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Contract</strong>
                </p>
                <p>
                  It is necessary to process this Personal Data to enter into
                  and perform our contract with you in relation to:
                </p>
                <ul>
                  <li>
                    your use of the Tito Services as an attendee and the Stripe
                    Services as a buyer
                  </li>
                </ul>
                <p>
                  If you do not wish to provide us with your Personal Data for
                  these purposes, we will not be able to enter into or perform
                  our contract(s) with you and you.
                </p>
              </td>
              <td>
                <p>
                  <strong>Providing the Tito Services</strong>
                </p>
                <ul>
                  <li>to ensure the smooth running of the Tito Services;</li>
                  <li>
                    to process your payments, through the Stripe third party
                    payment provider
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Legitimate Interests</strong>
                </p>
                <p>
                  It is in our legitimate interests to collect and process your
                  Personal Data for the purposes of improving your Summit
                  experience.
                </p>
                <p>
                  It is also necessary for the purposes of our legitimate
                  interests to process your Personal Data to respond to any
                  queries or requests submitted by you to us.
                </p>
                <p>
                  Before we process your Personal Data to pursue our legitimate
                  interests for these purposes, we determine if such processing
                  is necessary and we carefully consider the impact of our
                  processing activities on your fundamental rights and freedoms.
                  On balance, we have determined that such processing is
                  necessary for our legitimate interests and that the processing
                  which we conduct does not adversely impact on these rights and
                  freedoms.
                </p>
              </td>
              <td>
                <p>
                  <strong>Responding to Queries</strong>
                </p>
                <ul>
                  <li>
                    To process and respond to any queries or requests you submit
                    to us whether through the Email or otherwise
                  </li>
                </ul>
                <p>
                  <strong>Customer Profiling</strong>
                </p>
                <br />
                <p>
                  <strong>News and Marketing</strong>
                </p>
                <ul>
                  <li>
                    To send you a personal introduction email when you first
                    sign up for the Summit;
                  </li>
                  <li>to keep you updated with our news; and</li>
                  <li>
                    to send you promotional and marketing material which we
                    believe would be of use or interest to you.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Compliance with a Legal Obligation</strong>
                </p>
                <p>
                  We may process your Personal Data where it is necessary to
                  comply with legal obligations to which we are subject.
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    To comply with our obligations under Portuguese and European
                    law.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>
                    To Defend, Establish or be a Party to Legal Claims
                  </strong>
                </p>
                <p>
                  We may process your Personal Data as necessary for us to
                  establish, investigate, exercise or defend a legal claim to
                  which you are a party.
                </p>
              </td>
              <td>
                <ul>
                  <li>To file legal proceedings;</li>
                  <li>
                    to investigate, establish, exercise or defend a legal claim;
                    and
                  </li>
                  <li>to settle legal claims.</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </Fragment>
    );
  }

  renderPersonalDataDisclosure() {
    return (
      <Fragment>
        <h2>5. DISCLOSURE OF YOUR PERSONAL DATA</h2>
        <p>
          We may disclose some or all the Personal Data we collect from and
          obtain about you to the following third parties:
        </p>
        <p>
          <strong>
            Regulatory Authorities, Law Enforcement Agencies, Public Bodies and
            Other Third-Party Companies
          </strong>
        </p>
        <ul>
          <li>
            To comply with any applicable legal obligation, court order,
            summons, search warrants, or any other legal or regulatory
            obligation or request to which the ILS Team is or may become
            subject; and
          </li>
          <li>
            to protect the rights, property or safety of the ILS Team, our
            customers, or others. This includes exchanging information with
            other companies and organisations for the purposes of fraud
            protection.
          </li>
        </ul>
        <p>
          <strong>Other Recipients</strong>
        </p>
        <p>
          We may share your Personal Data with other third parties as and when
          necessary, including:
        </p>
        <ul>
          <li>
            external advisors such as our lawyers, accountants and auditors.
          </li>
        </ul>
      </Fragment>
    );
  }

  renderPersonalDataRetention() {
    return (
      <Fragment>
        <h2>6. RETENTION OF YOUR PERSONAL DATA</h2>
        <p>
          In general, we expect to keep your Personal Data for as long as you
          sign up for the Summit plus a period of up to 1 year thereafter.
          Please note that in certain circumstances, we may hold your personal
          data for a different period, for example, if we believe in good faith
          that the law or a relevant regulator may reasonably in our view expect
          or require us to preserve or delete your Personal Data.
        </p>
      </Fragment>
    );
  }

  renderPersonalDataSafeguard() {
    return (
      <Fragment>
        <h2>7. HOW WE STORE AND SAFEGUARD YOUR PERSONAL DATA</h2>
        <p>
          We care about protecting your information. We are committed to taking
          reasonable and appropriate steps to protect the Personal Data that we
          hold from misuse, loss, or unauthorised access. We do this by having
          in place a range of appropriate technical and organisational measures.
        </p>
      </Fragment>
    );
  }
  renderRights() {
    return (
      <Fragment>
        <h2>8. YOUR RIGHTS AND HOW TO EXERCISE THEM</h2>
        <p>
          You have several rights in relation to your Personal Data, which are
          set out in this Section 8. Note that in certain circumstances these
          rights might not be absolute.
        </p>
        <table>
          <thead>
            <tr>
              <th>Right</th>
              <th>Further information</th>
            </tr>
          </thead>
          <tbody>
            <tr></tr>
            <tr>
              <td>
                <p>Right to be Informed</p>
              </td>
              <td>
                <ul>
                  <li>
                    You have the right to know whether your Personal Data is
                    being processed by us, how we use your Personal Data and
                    your rights in relation to your Personal Data.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>Right of Access</p>
              </td>
              <td>
                <ul>
                  <li>
                    You have the right to request a copy of the Personal Data
                    held by us about you and to access the information which we
                    hold about you. We will only charge you for making such an
                    access request where we feel your request is unjustified or
                    excessive.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>Right to Rectification</p>
              </td>
              <td>
                <ul>
                  <li>
                    You have the right to have any inaccurate Personal Data
                    which we hold about you updated or corrected.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>Right to Erasure</p>
              </td>
              <td>
                <ul>
                  <li>
                    In certain circumstances, you may also have the Personal
                    Data that we hold about you deleted, for example if you
                    exercise your right to object and we do not have an
                    overriding reason to process your Personal Data or if we no
                    longer require your Personal Data for the purposes set out
                    in this notice.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>Right to Restriction of Processing</p>
              </td>
              <td>
                <ul>
                  <li>
                    You have the right to ask us to restrict processing your
                    Personal Data in certain cases, including if you believe
                    that the Personal Data we hold about you is inaccurate or
                    that our use of your Personal Data is unlawful. If you
                    validly exercise this right, we will store your Personal
                    Data and will not carry out any other processing on it until
                    the issue is resolved.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>Right to Object</p>
              </td>
              <td>
                <ul>
                  <li>
                    You have a right to object at any time to the processing of
                    your Personal Data where we process your Personal Data on
                    the legal basis of pursuing our legitimate interests.
                  </li>
                  <li>
                    Please note that you have the right to object to our
                    processing of your Personal Data for the purposes of sending
                    you marketing and news.
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          You can exercise any of these rights by submitting a request to{' '}
          <a href="mailto:wieilsportugal+privacy@gmail.com">
            wieilsportugal+privacy@gmail.com
          </a>
          .
        </p>
        <p>
          We will provide you with information on any action taken in relation
          to any of these rights upon your request without undue delay and at
          the latest within 1 month of receiving your request. We may extend
          this timeframe by one more month if necessary, however we will inform
          you if this arises. Please note that we may ask you to verify your
          identity when you seek to exercise any of your data protection rights.
        </p>
        <p>
          You also have the right to lodge a complaint with the Data Protection
          Commission. For further information see{' '}
          <a href="http://www.dataprotection.ie/">www.dataprotection.ie</a>.
        </p>
      </Fragment>
    );
  }

  renderQuestions() {
    return (
      <Fragment>
        <h2>9. CHANGES TO THIS NOTICE AND QUESTIONS</h2>
        <p>
          We may amend this Notice on occasion, in whole or in part, at our sole
          discretion. Any changes will be effective immediately upon
          communicating the revised Notice to you.
        </p>
        <p>
          If at any time we decide to use your Personal Data in a manner
          significantly different from that stated in this Notice, or otherwise
          disclosed to you at the time it was collected, we will notify you by
          the Email, and you will have a choice as to whether or not we use your
          Personal Data in the new manner.
        </p>
      </Fragment>
    );
  }
  render() {
    return (
      <div className="PrivacyPolicy container">
        <h1>Privacy Policy</h1>
        {this.renderPurpose()}
        {this.renderNoticeApplication()}
        {this.renderPersonalData()}
        {this.renderPersonalDataPurpose()}
        {this.renderPersonalDataDisclosure()}
        {this.renderPersonalDataRetention()}
        {this.renderPersonalDataSafeguard()}
        {this.renderRights()}
        {this.renderQuestions()}
      </div>
    );
  }
}
