import React from 'react';
import { Link } from 'react-router-dom';
import PersonCard from '../../components/person-card';

const sponsorsByTier = {
  main: [
    {
      name: 'IEEE Photonics Society',
      source: 'photonics.jpg',
      url: 'https://www.photonicssociety.org/'
    }
  ],
  gold: [
    {
      name: 'INESC TEC',
      source: 'inesctec.svg',
      url: 'https://www.inesctec.pt/en'
    }
  ],
  silver: [
    { name: 'MOXY', source: 'moxy.png', url: 'https://moxy.studio/' },
    {
      name: 'IEEE Broadcast Technology Society',
      source: 'bts.jpg',
      url: 'https://bts.ieee.org/'
    }
  ],
  partner: [
    { name: 'Natixis', source: 'natixis.svg', url: 'https://www.natixis.com/' },
    { name: 'Blip', source: 'blip.png', url: 'https://blip.pt/' },
    {
      name: 'Pólo Zero',
      source: 'polo-zero.png',
      url: 'https://polozero.fap.pt/'
    },
    {
      name: 'Federação Académica do Porto',
      source: 'fap.jpg',
      url: 'https://www.fap.pt/'
    },
    {
      name: 'Department of Informatics Engineering (FEUP)',
      source: 'dei.png',
      url: 'https://dei.fe.up.pt/en/home/'
    },
    {
      name: 'Slido',
      source: 'slido.svg',
      url: 'https://www.sli.do/'
    }
  ],
  carrier: [{ name: 'TAP Portugal', source: 'tap.png', url: '/sponsor/tap' }]
};

const groupSponsors = (sponsors, sponsorsPerRow) => {
  const rows = [];

  while (sponsors.length > sponsorsPerRow) {
    rows.push(sponsors.slice(0, sponsorsPerRow));
    sponsors = sponsors.slice(sponsorsPerRow);
  }
  rows.push(sponsors);

  return rows;
};

const getSponsorLinkWrapper = sponsor => {
  let elem = (
    <PersonCard
      name={sponsor.name}
      source={require(`./../../assets/sponsors/${sponsor.source}`)}
    />
  );

  if (sponsor.url.includes('http'))
    return (
      <a className="SponsorsPage__card" href={sponsor.url}>
        {elem}
      </a>
    );
  else
    return (
      <Link className="SponsorsPage__card" to={sponsor.url}>
        {elem}
      </Link>
    );
};

const renderSponsorTier = (title, width, sponsors) => {
  let sponsorsPerRow;
  if (width < 768) sponsorsPerRow = 2;
  else if (width < 1000) sponsorsPerRow = 3;
  else if (width < 1670) sponsorsPerRow = 4;
  else if (width < 1920) sponsorsPerRow = 5;
  else sponsorsPerRow = 6;
  return (
    <div className="SponsorsPage__tierContainer">
      <h1 className="SponsorsPage__sponsorTierTitle">{title}</h1>
      {groupSponsors(sponsors, sponsorsPerRow).map(row => (
        <div className="SponsorsPage__sponsorList">
          {row.map(sponsor => getSponsorLinkWrapper(sponsor))}
        </div>
      ))}
    </div>
  );
};

const SponsorsPage = ({ width }) => (
  <div className="SponsorsPage__container">
    <div className="SponsorsPage__textContainer">
      <h1 className="SponsorsPage__title">Sponsors</h1>
      <h2 className="SponsorsPage__subtitle">
        We thank all our sponsors for their support and enthusiasm
      </h2>
    </div>
    <div className="SponsorsPage__sponsorsContainer">
      {renderSponsorTier('Main Sponsor', width, sponsorsByTier.main)}
      {renderSponsorTier('Gold Sponsor', width, sponsorsByTier.gold)}
      {renderSponsorTier('Silver Sponsors', width, sponsorsByTier.silver)}
      {renderSponsorTier('Partners', width, sponsorsByTier.partner)}
      {renderSponsorTier('Official Carrier', width, sponsorsByTier.carrier)}
    </div>
  </div>
);

export default SponsorsPage;
