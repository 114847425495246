import React from 'react';
import Countdown from '../countdown';
import geolocation_icon from '../../assets/geolocation.svg';
const CountdownCard = props => (
  <div className="CountdownCard__container">
    <div className="CountdownCard__countdownContainer">
      <Countdown {...props} />
    </div>
    <div className="CountdownCard__infoContainer">
      <p className="CountdownCard__textBold">{'25, 26 & 27 July'}</p>
      <div className="CountdownCard__secondLineContainer">
        <p className="CountdownCard__secondLine">at</p>
        <p className="CountdownCard__textBold">FEUP</p>
        <div className="CountdownCard__pinIcon">
          <img src={geolocation_icon} alt="geolocation-icon" />
        </div>
      </div>
    </div>
  </div>
);

export default CountdownCard;
